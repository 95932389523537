html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: black;
}

@font-face {
  font-family: neon;
  src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf);
}

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  max-height: 10vh;
  margin-top: 45vh;
}

.neon {
  font-family: neon;
  color: #ca3e47;
  font-size: 3vw;
  line-height: 3vw;
  text-shadow: 0 0 3vw #ca3e47;
}

.flux {
  font-family: neon;
  color: #426dfb;
  font-size: 9vw;
  line-height: 9vw;
  text-shadow: 0 0 3vw #2356ff;
}

.neon {
  animation: neon 1s ease infinite;
  -moz-animation: neon 1s ease infinite;
  -webkit-animation: neon 1s ease infinite;
}

@keyframes neon {
  0%,
  100% {
    text-shadow: 0 0 1vw #ca3e47, 0 0 3vw #ca3e47, 0 0 10vw #ca3e47,
      0 0 10vw #ca3e47, 0 0 0.4vw #ca3e97;
    color: #ca3e47;
  }
  50% {
    text-shadow: 0 0 0.5vw #8c2b32, 0 0 1.5vw #8c2b32, 0 0 5vw #8c2b32,
      0 0 5vw #8c2b32, 0 0 0.2vw #8c2b32;
    color: #ca3e47;
  }
}

.flux {
  animation: flux 2s linear infinite;
  -moz-animation: flux 2s linear infinite;
  -webkit-animation: flux 2s linear infinite;
  -o-animation: flux 2s linear infinite;
}

@keyframes flux {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}
